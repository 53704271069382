import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { cls } from '@/utils'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
import IconMinus from '@haiper/icons-svg/icons/outline/minus-square.svg'
import IconX from '@/public/assets/icon-x.svg'
import IconQuestion from '@haiper/icons-svg/icons/outline/questionmark.svg'
import { useCallback, useState } from 'react'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useCachedSwitches } from '@/hooks/useSwitches'
import Image from '@/components/image'

export interface SamPromptProps {
  className?: string
  triggerClassName?: string
}

export default function SamPrompt({ className, triggerClassName }: SamPromptProps) {
  const handleGotoDetail = useCallback((e: any) => {
    window.open('https://book.haiper.ai/haiper-video-generator/creations/repaint-your-video', '_blank')
    e?.preventDefault()
  }, [])
  const preventDefault = useCallback((e: any) => {
    e?.preventDefault()
  }, [])

  const [popoverOpen, setPopoverOpen] = useState(false)
  const closePopover = useCallback((e: any) => {
    e?.preventDefault()
    e?.stopPropagation()
    setPopoverOpen(false)
  }, [])
  const { data: switches } = useCachedSwitches()
  const disableSam = !switches?.inpainting

  const { isBelowMd } = useBreakpoint('md')

  const trigger = (
    <Button
      variant='secondary'
      className={cls(
        'size-10 rounded-full p-2.5 bg-surface absolute right-0 bottom-0 border border-border border-solid px-0 flex items-center justify-center shadow-sm hover:border-border-hover',
        triggerClassName,
      )}
      type='button'
    >
      <IconQuestion className={cls('size-6 inline-block text-icon opacity-100 group-hover:opacity-100')} />
    </Button>
  )

  const content = (
    <div className={cls('flex flex-col', className)}>
      <Image
        className='w-full aspect-video'
        src='https://static2.haiper.ai/public/assets/vary-region-demo.gif'
        alt='demo'
      />
      <div className='mt-3 text-body-md'>
        Click on the object you want to repaint. Large, well-defined objects are more likely to give desirable results
      </div>
      <div className='flex mt-2 w-full gap-4'>
        <div className='flex gap-2 items-center'>
          <div className='rounded-lg size-6 p-1.5 flex items-center justify-center'>
            <IconPlus className='shrink-0' />
          </div>
          <span className='text-body-md'>to add</span>
        </div>
        <div className='flex gap-2 items-center'>
          <div className='rounded-lg size-6 p-1.5 flex items-center justify-center'>
            <IconMinus className='shrink-0' />
          </div>
          <span className='text-body-md'>to remove</span>
        </div>
      </div>
    </div>
  )

  const cancelText = 'More Detail'
  const okText = 'Got it'

  if (disableSam) {
    return null
  }

  if (isBelowMd) {
    // dialog in mobile
    return (
      <Dialog
        trigger={trigger}
        title='How to vary a region?'
        aria-label='sam-prompt-content'
        okText={okText}
        cancelText={cancelText}
        onCancel={handleGotoDetail}
        onInteractOutside={preventDefault}
      >
        {content}
      </Dialog>
    )
  }

  // tooltip in desktop
  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild className='absolute size-10 right-0 bottom-0'>
        {trigger}
      </PopoverTrigger>
      <PopoverContent className='w-[335px]' data-component='creation-input' data-outside='false'>
        <div className='flex flex-col px-2 py-1 w-full'>
          <div className='flex justify-between items-center text-text mb-3' aria-label='title'>
            <span>How to vary a region?</span>
            <IconX
              className='size-6 text-text cursor-pointer border-none hover:bg-surface-hover rounded-lg'
              onClick={closePopover}
            />
          </div>
          {content}
          <div className='flex gap-4 justify-end mt-6'>
            <Button variant='outline' className={cls('rounded-lg')} onClick={handleGotoDetail}>
              {cancelText}
            </Button>
            <Button variant='primary' className={cls('rounded-lg')} onClick={closePopover}>
              {okText}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
