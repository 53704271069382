import { useCallback } from 'react'
import useAuth0Auth from './useAuth0Auth'
import useHaiperAuth from './useHaiperAuth'
import { USE_HAIPER_AUTH } from '@/constants'
import useIsLogin from './useIsLogin'
import { AUTH_DOMAIN } from '@/service/auth.service'

const useAuth = () => {
  const { login: auth0Login, error, isLoading: auth0Loading, logout: auth0Logout } = useAuth0Auth()
  const { login: haiperLogin, logout: haiperLogout, isLoading: haiperLoading } = useHaiperAuth()

  const login = USE_HAIPER_AUTH ? haiperLogin : auth0Login

  const loading = USE_HAIPER_AUTH ? haiperLoading : auth0Loading

  const logout = useCallback(() => {
    auth0Logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/signin${location.search}`,
      },
    })
    haiperLogout()
  }, [auth0Logout, haiperLogout])

  const isLogin = useIsLogin()

  const googleLogin = useCallback(() => {
    location.href = `https://${AUTH_DOMAIN}/auth/google?callback=${window.location.origin}/auth/callback/passport`
  }, [])

  const discordLogin = useCallback(() => {
    location.href = `https://${AUTH_DOMAIN}/auth/discord?callback=${window.location.origin}/auth/callback/passport`
  }, [])

  const appleLogin = useCallback(() => {
    location.href = `https://${AUTH_DOMAIN}/auth/apple?callback=${window.location.origin}/auth/callback/passport`
  }, [])

  const emailGetCode = useCallback(() => {}, [])

  const emailLogin = useCallback(() => {}, [])

  return {
    login,
    auth0Login,
    googleLogin,
    discordLogin,
    appleLogin,
    emailGetCode,
    emailLogin,
    logout,
    isLogin,
    isLoading: loading,
    error,
  }
}

export default useAuth
