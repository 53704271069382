import { getStripeUserSubscription } from '@/service/pay.service'
import { UserSubscription } from '@/types'
import useService, { useCachedService } from '@banyudu/use-service'
import { useMemo } from 'react'
import useIsLogin from './useIsLogin'

const skipableGetStripeUserSubscription = async (params?: { skip?: boolean }) => {
  if (params?.skip) return null
  const res = await getStripeUserSubscription()
  return res
}

const _useSubscription = useService(skipableGetStripeUserSubscription, (params) => !params?.skip, {
  keepPreviousData: true,
})
const _useCachedSubscription = useCachedService(skipableGetStripeUserSubscription, (params) => !params?.skip)

const useSubscription = (
  refreshKey?: string,
): {
  data: UserSubscription | null
  loading: boolean
} => {
  const isLogin = useIsLogin()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  const { data: subscription, isValidating: loading } = _useSubscription(params, refreshKey)

  return {
    data: subscription ?? null,
    loading,
  }
}

export const useCachedSubscription = (): {
  data: UserSubscription | null
  refresh: () => void
  loading: boolean
} => {
  const isLogin = useIsLogin()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  const { data: subscription, isValidating: loading, refresh } = _useCachedSubscription(params)

  return {
    data: subscription ?? null,
    loading,
    refresh,
  }
}

export default useSubscription
