import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
import GSUpload from '@/components/gs-upload'
// import Suggestion from './suggestion'
import { ButtonVaryRegion } from '../button-vary-region'
import SamPrompt from '../sam-prompt'
import { SamWrapper } from '../sam-wrapper'
import { useCachedSwitches } from '@/hooks/useSwitches'
import useModelVersion from '@/hooks/useModelVersion'
import { ModelVersion } from '@/types'
import Seperator from '@/components/seperator'
import { SHOW_FF_IMAGE_INPUT_FOR_V2V } from '@/constants'

export default function V2vControls({
  expanded,
  setFile,
  uploading,
  setUploading,
  fileId,
  file,
  firstFrameFile,
  setFirstFrameFile,
  creation,
  textarea,
  onSamChange,
  onDeleteFile,
}: CreationInputControlsProps) {
  const { data: switches } = useCachedSwitches()
  const { version } = useModelVersion()
  const enableSAM = !!switches?.sam && version !== ModelVersion.TWO
  const showSAM = enableSAM && !!fileId
  const showFirstFrameUpload = !!fileId && SHOW_FF_IMAGE_INPUT_FOR_V2V && version === ModelVersion.TWO

  return (
    <fieldset
      className={cls(
        'self-start flex md:relative p-3 pt-0 bg-surface w-full shrink-0',
        'flex flex-col justify-center gap-2 min-h-0 md:min-h-[auto]',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      <div
        className={cls('grid grid-cols-1 items-center md:mx-auto gap-3 py-1', showFirstFrameUpload && 'grid-cols-2')}
      >
        <div className='flex flex-col items-center gap-3'>
          <div className='flex flex-col gap-2 items-center'>
            <span className='text-body-md font-medium'>Input Video</span>
            <GSUpload
              file={file}
              fileType='video'
              className={cls(
                'bg-surface hover:bg-surface-hover rounded-lg border border-solid border-border hover:border-border-hover',
                'md:w-[300px] md:h-[180px]',
                showSAM ? 'hidden' : '',
              )}
              uploading={uploading}
              setUploading={setUploading}
              onChange={setFile}
            />
          </div>
          {showSAM ? (
            <SamWrapper
              key={fileId}
              className=''
              fileId={fileId}
              thumbnailUrl={file?.thumbnailUrl ?? creation?.thumbnail_url ?? ''}
              onChange={onSamChange}
              onDelete={onDeleteFile}
            />
          ) : (
            <div className={cls('flex relative justify-center w-full', enableSAM ? '' : 'hidden h-4')}>
              <ButtonVaryRegion disabled className={cls('')} />
              <SamPrompt />
            </div>
          )}
        </div>
        {showFirstFrameUpload ? (
          <div className='flex items-center h-full'>
            <Seperator className='hidden md:block' />
            <div className='flex flex-col items-center gap-2 h-full'>
              <span className='text-body-md font-medium'>First Frame</span>
              <GSUpload
                file={firstFrameFile}
                fileType='image'
                className={cls(
                  'bg-surface hover:bg-surface-hover rounded-lg border border-solid border-border hover:border-border-hover',
                  'md:w-[300px] md:h-full',
                  fileId ? '' : 'hidden',
                )}
                emptyText='Optional: Upload a first frame'
                onChange={setFirstFrameFile}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className='flex w-full flex-col'>{textarea}</div>
    </fieldset>
  )
}
