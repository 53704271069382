import { expandSpotlights } from '@/service/spotlight.service'
import useService, { useCachedService } from '@banyudu/use-service'

const useExpandedSpotlights = useService(expandSpotlights, (params) => params?.length > 0, {
  keepPreviousData: true,
})

export const useCachedExpandedSpotlights = useCachedService(expandSpotlights, (params) => params?.length > 0, {
  keepPreviousData: true,
})

export default useExpandedSpotlights
