import useService, { useCachedService } from '@banyudu/use-service'
import { useMemo } from 'react'
import { getTobPricingTable } from '@/service/tob.service'

const skipableGetStripePricingTable = async (params?: { skip?: boolean }) => {
  if (params?.skip) return null
  const res = await getTobPricingTable()
  return res
}

const _useToBPricingTable = useService(skipableGetStripePricingTable)
const useToBPricingTable = () => {
  const params = useMemo(() => {
    return {}
  }, [])

  return _useToBPricingTable(params)
}

const _useCachedToBPricingTable = useCachedService(skipableGetStripePricingTable)

export const useCachedToBPricingTable = () => {
  const params = useMemo(() => {
    return {}
  }, [])

  return _useCachedToBPricingTable(params)
}

export default useToBPricingTable
