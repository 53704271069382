import { useCachedService } from '@banyudu/use-service'
import { getCreditPricingTable } from '@/service/pay.service'
import useAuthedSkip from './useAuthedSkip'

// const skipableGetCreditPricingTable = async (params?: { skip?: boolean }) => {
//   if (params?.skip) return null
//   const res = await getCreditPricingTable()
//   return res
// }

// const _useCachedCreditPricingTable = useCachedService(skipableGetCreditPricingTable)

// export const useCachedCreditPricingTable = () => {
//   const isLogin = useIsLogin()

//   const params = useMemo(() => {
//     if (!isLogin) return { skip: true }
//     return {}
//   }, [isLogin])

//   return _useCachedCreditPricingTable(params)
// }

export const useCachedCreditPricingTable = useCachedService(getCreditPricingTable, useAuthedSkip())

// export default useCreditPricingTable
