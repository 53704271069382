import { Profile, Me } from '@/types'
import { isProduction, rest, whisper } from '@/utils'

export const AUTH_DOMAIN = isProduction ? 'auth1-vb.haiper.ai' : 'auth1-vb-dev.haiper.ai'

export const discordAuth = async (code: string): Promise<string> => {
  const res = await rest.get('/v1/auth/discord', {
    params: {
      code,
      redirect_uri: `${window.location.origin}/auth/callback/discord`,
    },
  })
  return res.data
}

export const getMe = async (): Promise<Me> => {
  const res = await rest.get('/v1/user/me')
  return res.data
}

export const getProfile = async (): Promise<Profile> => {
  const res = await rest.get('/v1/user/profile')
  return res.data
}

export const updateProfile = async (data: Partial<Profile>): Promise<void> => {
  const res = await rest.put('/v1/user/profile', data)
  return res.data
}

export const sendCaptcha = async (email: string): Promise<any> => {
  const res = await rest.post(`https://${AUTH_DOMAIN}/request-code`, { email })
  return res.data
}

export const emailLogin = async (email: string, code: string): Promise<any> => {
  const res = await rest.post(`https://${AUTH_DOMAIN}/auth/login`, { email, code })
  return res.data
}
