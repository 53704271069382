import { useAtomValue } from 'jotai'
import { authorizedAtom } from '@/atoms'
import { isNil } from 'lodash-es'
import { whisper } from '@/utils'

// wrapper the skip function for the useService/useCachedService hook
const useAuthedSkip = <T = any>(skip?: (p: any) => boolean) => {
  return (p: T) => {
    const hasSignIn = useAtomValue(authorizedAtom)
    if (!hasSignIn) return false
    if (isNil(skip)) return true
    return skip(p)
  }
}

export default useAuthedSkip
