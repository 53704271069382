import { authorizedAtom, loginDialogOpenAtom } from '@/atoms'
import { CACHE_AUTH_REDIRECT_URL } from '@/constants'
import { clearAuthCache } from '@/utils'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

const useHaiperAuth = () => {
  const loginWithRedirect = useCallback(() => {
    const redirectUrl = `${window.location.pathname}${window.location.search}`
    sessionStorage.setItem(CACHE_AUTH_REDIRECT_URL, redirectUrl)
    location.href = '/auth/signin'
  }, [])

  const setIsLogin = useSetAtom(authorizedAtom)
  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)

  const login = useCallback(() => {
    const redirectUrl = `${window.location.pathname}${window.location.search}`
    sessionStorage.setItem(CACHE_AUTH_REDIRECT_URL, redirectUrl)
    setLoginDialogOpen(false)
    loginWithRedirect()
  }, [loginWithRedirect, setLoginDialogOpen])

  const refreshToken = useCallback(() => {}, [])

  const signOut = useCallback(() => {
    clearAuthCache()
    setIsLogin(false)
  }, [setIsLogin])

  return {
    loginWithRedirect,
    isLoading: false,
    logout: signOut,
    refreshToken,
    setIsLogin,
    login,
  }
}

export default useHaiperAuth
