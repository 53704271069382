'use client'

import { usePathname, useRouter } from 'next/navigation'
import { ReactNode, useEffect } from 'react'
import Loading from '@/components/loading'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import useAmplitude from '@/hooks/useAmplitude'
import useGoAuth from '@/hooks/useGoAuth'
import { useSetAtom } from 'jotai'
import { authLoading, authorizedAtom } from '@/atoms'
import { isOldUser } from '@/utils'
import useAuth from '@/hooks/useAuth'
import useIsLogin from '@/hooks/useIsLogin'

const bizWhiteLists = [
  // /^\/membership$/,
  /^\/onboarding$/,
  /^\/onboarding2$/,
  /^\/haiper-api(\/.+)?$/,
  /^\/creation\/.+/,
  // /^\/template\/.+/,
  /^\/spotlight(\/.+)$/,
  // /^\/explore\/?$/,
]

const whiteLists = [
  /^\/auth\//,
  /^\/404/,
  /^\/debug\//,
  /^\/playground\//,
  // /^\/$/,
  ...bizWhiteLists,
]

export default function Guard({ children }: { children: ReactNode }) {
  const isLogin = useIsLogin()
  const { isLoading: authProfileLoading } = useAuth()
  // const setSignedIn = useSetAtom(authorizedAtom)
  // const setAuthLoading = useSetAtom(authLoading)
  const router = useRouter()

  const pathname = usePathname()
  const goAuth = useGoAuth()

  // useEffect(() => {
  //   // setSignedIn(isLogin)
  //   // setAuthLoading(authProfileLoading)
  // }, [isLogin, setSignedIn, authProfileLoading, setAuthLoading])

  const { data: userProfile, isValidating: userProfileLoading } = useCachedMyProfile()
  const { setUserCreateTime, setUserId } = useAmplitude()

  useEffect(() => {
    if (!userProfileLoading && userProfile) {
      setUserId(userProfile.user_id)
      setUserCreateTime(userProfile.create_time)
    }
  }, [userProfile, userProfileLoading, setUserCreateTime, setUserId])

  const isWhiteList = whiteLists.some((regex) => regex.test(pathname))

  if (isWhiteList && !authProfileLoading) {
    return children
  }

  if (authProfileLoading || userProfileLoading)
    return (
      <div className='size-full flex items-center justify-center text-text flex-1'>
        <Loading />
      </div>
    )

  if (!isLogin && !isWhiteList) {
    // if (pathname === '/' && getLocalStorage(CACHE_KEYS.ONBOARDING_DATA) === null) {
    if (pathname === '/' || pathname === '/explore' || pathname === '/templates') {
      // A/B test, 20% of new users will be redirected to /onboarding2, the rest will be redirected to /onboarding
      if (!isOldUser()) {
        // UPDATE 2024-10-17: Remove A/B test, /onboarding2 is slightly worse than /onboarding. 23% vs 25% conversion rate.
        // const lastPickKey = 'onboarding_ab_test_last_pick'
        // const lastPick = getSessionStorage<string>(lastPickKey)
        // const onboardingPath = ['/onboarding', '/onboarding2'].includes(lastPick ?? '')
        //   ? lastPick
        //   : Math.random() < 0.2
        //     ? '/onboarding2'
        //     : '/onboarding'
        // setSessionStorage(lastPickKey, onboardingPath)

        const onboardingPath = '/onboarding'
        router.replace(`${onboardingPath}${window.location.search}`)
        return
      }
    }
    goAuth()
    return null
  }

  return children
}
