import Button from '@/components/button'
import LoginOutline from '@haiper/icons-svg/icons/outline/login.svg'
import { cls } from '@/utils'
import { useAtomValue } from 'jotai'
import { authorizedAtom } from '@/atoms'
import useAuth from '@/hooks/useAuth'

export interface LoginMaskProps {
  text?: string
  className?: string
}

const defaultText = 'Login to create'

export default function LoginMask({ className, text = defaultText }: LoginMaskProps) {
  const hasSignIn = useAtomValue(authorizedAtom)
  const { login } = useAuth()

  if (hasSignIn) {
    return null
  }

  return (
    <div className={cls('absolute inset-0 backdrop-blur-[5px] flex items-center justify-center z-20', className)}>
      <Button
        variant='primary'
        className={cls(
          'border-2 border-b-4 border-surface-primary-hover',
          hasSignIn === false ? 'visible' : 'invisible',
        )}
        onClick={() => login()}
      >
        <div className='flex gap-1 items-center justify-center'>
          <LoginOutline className='text-icon-on-color' />
          <span className='text-text-on-color px-1'>{text}</span>
        </div>
      </Button>
    </div>
  )
}
