import { OFFLINE_TEMPLATE_CATEGORIES, OFFLINE_TEMPLATES } from '@/constants/legacy'
import type { Template, TemplateCategory } from '@/types'

export const getTemplate = async (id: string): Promise<Template | null> => {
  const template = OFFLINE_TEMPLATES.find((template) => template.template_id === id)
  return template ?? null
  // const res = await rest.get(`/v1/template/${id}`)
  // return res.data
}

export const getTemplateCategories = async (): Promise<TemplateCategory[]> => {
  return OFFLINE_TEMPLATE_CATEGORIES
  // const res = await rest.get('/v1/template/categories')
  // return res.data
}
