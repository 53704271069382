'use client'

import { useInfinitePublished } from '@/hooks/usePublished'
import { cls } from '@/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCachedVisibleMarketingEvents } from '@/hooks/useVisibleMarketingEvents'
import ButtonTabs from '@/components/button-tabs'
import { TabsProps } from '@/components/tabs'
import Button from '@/components/button'
import IconFilterSort from '@haiper/icons-svg/icons/outline/filter-sort.svg'
import { GetPublishedQuery } from '@/service/submission.service'
import Works from '@/components/works'

export interface SpotlightWorksProps {
  className?: string
}

const cacheMargin = 12

const maxEventFilterCount = 5

export default function SpotlightWorks({ className }: SpotlightWorksProps) {
  const { data: events } = useCachedVisibleMarketingEvents()
  const eventsWithFilterEnabled = useMemo(() => events?.filter((event) => !!event.show_submission_filter), [events])

  const [sortBy, setSortBy] = useState<'time' | 'rank'>('rank')

  const filterTabs: TabsProps['items'] = useMemo(() => {
    return [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'AI Story',
        value: 'shorts',
      },
      {
        label: 'Creations',
        value: 'creations',
      },
      {
        label: 'Social Media',
        value: 'social_media',
      },
      ...eventsWithFilterEnabled?.slice(0, maxEventFilterCount).map((event) => ({
        label: event.title,
        value: `event:${event.event_id}`,
      })),
    ]
  }, [eventsWithFilterEnabled])

  const [activeFilter, setActiveFilter] = useState<string>('')

  const queryParams = useMemo(() => {
    const res: GetPublishedQuery = {
      sort_by: sortBy,
    }
    if (activeFilter === 'video') {
      res.work_types = 'creation_video,spotlight'
    } else if (activeFilter === 'shorts') {
      res.work_types = 'spotlight'
    } else if (activeFilter === 'creations') {
      res.work_types = 'creation_video,creation_image'
    } else if (activeFilter === 'image') {
      res.work_type = 'creation_image'
    } else if (activeFilter.startsWith('event:')) {
      res.event_id = activeFilter.replace('event:', '')
    } else if (activeFilter === 'social_media') {
      res.social_post_exist = true
    }
    return res
  }, [sortBy, activeFilter])

  const {
    data: published,
    loadMore,
    isValidating: worksLoading,
  } = useInfinitePublished(queryParams, undefined, {
    keepPreviousData: true,
  })

  const hasMore = true // BE doesn't return the total count, so we always assume there are more

  const latestGalleryItemsRef = useRef<haiper.Work[]>([])

  const galleryItems = useMemo(() => {
    return published?.records ?? []
  }, [published])

  const realGalleryItems: haiper.Work[] = useMemo(() => {
    return galleryItems?.length ? galleryItems : latestGalleryItemsRef.current
  }, [galleryItems])

  useEffect(() => {
    if (!worksLoading) {
      latestGalleryItemsRef.current = galleryItems
    }
  }, [worksLoading, galleryItems])

  const checkLoadMore = useCallback(
    (target: haiper.Work) => {
      const index =
        galleryItems?.findIndex(
          (item: haiper.Work) => item.work_id === target.work_id && item.work_type === target.work_type,
        ) ?? -1

      if (index > (galleryItems?.length ?? 0) - cacheMargin && hasMore) {
        loadMore?.()
      }
    },
    [loadMore, galleryItems, hasMore],
  )

  return (
    <div className={cls('flex flex-col gap-4 w-full', className)} aria-label='spotlight works'>
      <div className='w-full flex justify-between items-center' aria-label='filters'>
        <ButtonTabs className='h-9' value={activeFilter} items={filterTabs} onChange={setActiveFilter} />
        <div className=''>
          <Button
            variant='transparent'
            className={cls('h-8 px-2 py-1')}
            onClick={() => setSortBy((old) => (old === 'rank' ? 'time' : 'rank'))}
          >
            <div className='flex items-center gap-1'>
              <IconFilterSort className='size-5 shrink-0 text-icon' />
              <span className='px-1 text-body-lg font-medium tracking-32'>{sortBy === 'rank' ? 'Hot' : 'Latest'}</span>
            </div>
          </Button>
        </div>
      </div>
      <Works data={realGalleryItems} loading={worksLoading} onItemShow={checkLoadMore} />
    </div>
  )
}
