import { activeTemplateIdAtom } from '@/atoms'
import { useAtomValue } from 'jotai'
import { useCachedTemplate } from './useTemplate'
import { Template, TemplateDetail } from '@/types'

const useActiveTemplate = () => {
  const templateId = useAtomValue(activeTemplateIdAtom)

  const res = useCachedTemplate(templateId ?? '')
  return {
    ...res,
    data: ((res?.data as TemplateDetail | undefined) ?? null) as Template | TemplateDetail | null,
    id: templateId,
  }
}

export default useActiveTemplate
