'use client'

import WithMSW from '@/components/with-msw'
import { ModalProvider } from '@/components/modal/provider'
import Guard from '@/components/with-guard'
import { BlockedDialog } from '@/components/blocked'
import VConsole from '@/components/vconsole'
import Persona from '../persona'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useAtomValue, useSetAtom } from 'jotai'
import { authLoading, authorizedAtom, getTobCreditDialogOpenAtom, versionDialogOpenAtom } from '@/atoms'
import { useCachedSwitches } from '@/hooks/useSwitches'
import WithAuth0 from '../with-auth0'
import Loading from '@/components/loading'
import LoginDialog from '@/components/login-dialog'
import { SubscriptionDialog } from '@/components/subscription-dialog'
import { SubscriptionRemind } from '@/components/subscription-remind'
import GetCreditDialog from '@/components/get-credit-dialog'
import { CreditProvider, useCreditValue } from '@/hooks/useCredit'
import InsufficientDialog from '@/components/insufficient-dialog'
import VersionDialog from '@/components/version-dialog'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { getLocalStorage, whisper } from '@/utils'
import GetTobCreditDialog from '@/components/get-tob-credit-dialog'
import FollowUsDialog from '@/components/follow-us-dialog'
import NewFeatureDialog from '@/components/new-feature-dialog'
import useSurvey from '@/hooks/useSurvery'
import { GenerationErrorDialog } from '../generation-error-dialog'
import { CheckinDialog } from '../checkin-dialog'

export default function WithCSR({ children, modal }: { children: React.ReactNode; modal?: React.ReactNode }) {
  const { data: profile } = useCachedMyProfile()
  const setVersionDialogOpen = useSetAtom(versionDialogOpenAtom)
  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const isAuth0Loading = useAtomValue(authLoading)
  const CreditModal = useCreditValue()
  const isLogin = useAtomValue(authorizedAtom)

  useEffect(() => {
    if (!profile) return
    const showTime = dayjs(getLocalStorage('versionShow') || undefined)
    if (dayjs() < dayjs('2024-7-30') && (!getLocalStorage('versionShow') || dayjs().diff(showTime, 'day') > 7)) {
      setVersionDialogOpen(true)
    }
  }, [profile, setVersionDialogOpen])
  const { showSurvey } = useSurvey()

  const loading = switchesLoading && !switches && isAuth0Loading

  const getCreditDialogOpen = useAtomValue(getTobCreditDialogOpenAtom)

  return (
    <WithMSW>
      <WithAuth0>
        <Guard>
          <ModalProvider>
            <CreditProvider value={CreditModal}>
              {loading ? (
                <div className='size-full flex items-center justify-center'>
                  <Loading />
                </div>
              ) : (
                children
              )}
              {modal}
              {showSurvey && <Persona />}
              <VersionDialog />
              <BlockedDialog />
              <LoginDialog />
              <SubscriptionDialog />
              <GenerationErrorDialog />
              <SubscriptionRemind />
              <GetCreditDialog />
              {getCreditDialogOpen && <GetTobCreditDialog />}
              <FollowUsDialog />
              <CheckinDialog />
              {isLogin && <NewFeatureDialog />}
              <InsufficientDialog />
            </CreditProvider>
          </ModalProvider>
        </Guard>
      </WithAuth0>
      <VConsole />
    </WithMSW>
  )
}
