'use client'
import { OnboardingData } from '@/types'
import { getImageResolution, getLocalStorage, setLocalStorage, whisper } from '@/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { uploadPromise } from '@/service/upload.service'
import { generateBB8 } from '@/service/job.service'
import useAmplitude from '@/hooks/useAmplitude'
import { CACHE_KEYS } from '@/constants'
import useIsLogin from './useIsLogin'

let generated = false

export default function useOnboarding() {
  const { track } = useAmplitude()
  const creatingRef = useRef<boolean>(false)
  const isLogin = useIsLogin()
  const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(
    getLocalStorage<OnboardingData>(CACHE_KEYS.ONBOARDING_DATA),
  )
  const [creationId, setCreationId] = useState<string>(onboardingData?.creationId ?? '')

  useEffect(() => {
    setLocalStorage(CACHE_KEYS.ONBOARDING_DATA, onboardingData)
  }, [onboardingData])

  const initCreation = useCallback(async () => {
    if (!onboardingData) {
      return
    }
    const { type, prompt, file } = onboardingData
    const { url, fileId, filename, mimeType } = file ?? {}

    if (creatingRef.current) {
      return
    }

    if (!type || !prompt) {
      return
    }

    creatingRef.current = true
    const params: any = {
      prompt,
      settings: {
        aspect_ratio: '16:9',
        duration: 4,
        seed: -1,
      },
    }

    if (type === 'i2v') {
      let realFileId = fileId
      if (!realFileId && url) {
        const response = await fetch(url)
        const blob = await response.blob()
        const file = new File([blob], filename ?? (mimeType === 'image/png' ? 'input.png' : 'input.jpg'), {
          type: mimeType,
        })
        realFileId = await uploadPromise({
          file,
          name: filename ?? (mimeType === 'image/png' ? 'input.png' : 'input.jpg'),
        })
      }
      params.config = params.config ?? {}
      params.config.source_image = realFileId
      const { width, height } = await getImageResolution(url ?? '')
      params.config.input_width = width
      params.config.input_height = height
    }

    if (generated) {
      return
    }

    const res = await generateBB8(params)
    generated = true
    setCreationId(res.generation_id)
    setOnboardingData((old: any) => {
      return {
        ...old,
        creationId: res.generation_id,
      }
    })
    // onGenerate?.(res.generation_id)

    track('click:onboarding:generate', {
      onboarding_creation_id: res.generation_id,
      onboarding_type: type,
    })
  }, [onboardingData, track])

  useEffect(() => {
    if (isLogin && !creationId) {
      initCreation().catch(console.error)
    }
  }, [isLogin, creationId, initCreation])
}
