export const OFFLINE_TEMPLATE_CATEGORIES = [
  {
    label: 'Hot',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/hot.svg',
    description: 'A collection of the most popular and highly recommended templates across all categories.',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-hot.webp',
    category: 'hot',
    tags: ['for_fun_&_giggles', 'for_commerical_use'],
  },
  {
    label: 'Meme',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/meme.svg',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-mem.webp',
    description: 'Pick a template, add your image, and watch your memes go viral.',
    category: 'meme',
  },
  {
    label: 'Dance',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/dance.svg',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-dance.webp',
    description:
      'Just upload your character\'s image, and watch them perform a variety of exciting dance moves in no time.',
    category: 'dance',
    tags: ['kpop_dance', 'trending_dance', 'remix_dance', 'meme_dance', 'south_america_party_dance'],
  },
  {
    label: 'Portrait',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/portrait.svg',
    description: 'Easily generate dynamic, character-centered videos in a variety of styles.',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-portrait.webp',
    category: 'portrait',
  },
  {
    label: 'Styles',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/styles.svg',
    description: 'Quickly create videos in a wide variety of unique styles.',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-style.webp',
    category: 'styles',
  },
  {
    label: 'Product',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/product.svg',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner.webp',
    description:
      'Turn your product images into stunning videos with just a few clicks! Upload and let them shine in a variety of engaging scenes.',
    category: 'product',
    tags: ['food_and_beverage', 'beauty', 'tech_gadgets', 'furniture'],
  },
  {
    label: 'Logo',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/logo.svg',
    category: 'logo',
  },
  {
    label: 'Basic Templates',
    icon: 'https://cdnvb5.haiper.ai/assets/webapp/images/templates/basic_tool2.svg',
    description: 'A diverse set of essential tools to bring all your creative ideas to life.',
    cover: 'https://cdnvb5.haiper.ai/assets/webapp/templates/banner/template-category-banner-basic.webp',
    category: 'basic',
  },
]

export const OFFLINE_TEMPLATES = [
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'meme',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/kungfu.webp',
    create_time: '2024-11-07T15:58:23.771Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/kungfu.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/kungfu.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Kung Fu',
    rank: 700,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-11-07T15:58:23.772Z',
    version: 1,
    tutorial:
      '\n### Kung Fu \n\n###### Create a video of the character performing Kungfu in a traditional Chinese setting.\n\n##### 1. Input your Image.\n\nClear images from the waist up with the person\'s face displayed in the centre.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/J6pXyVmW3nQcRtZ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/J6pXyVmW3nQcRtZ.mp4)\n',
    creation_num: 55,
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    template_id: '672ce39f6f9e6c6070079c72',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'meme',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/monster_morph.webp',
    create_time: '2024-10-31T11:18:25.822Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/monster_morph.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/monster_morph.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Monster Morph',
    rank: 650,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-31T11:18:25.826Z',
    version: 1,
    tutorial:
      '\n### Monster Morph \n\n###### Turn a person into a random creature of the night.\n\n##### 1. Input your Image.\n\nClear images from the waist up with the person in the center work better. Hands being off screen will help with movement.\nThe character\'s body should face forward or towards the other character to avoid incorrect movements.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/H8jRmWpYxN4vCtZ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/H8jRmWpYxN4vCtZ.mp4)\n',
    creation_num: 39,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    comment_num: 1,
    template_id: '67236782b7c22d70ef09aa96',
    commits: {
      is_like: false,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Kissing Video',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-10-10T11:14:56.894Z',
    update_time: '2024-10-10T11:14:56.894Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Kissing_Video.webp',
    category: 'meme',
    tags: ['hot', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/kiss_each_other.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Kissing_Video.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 640,
    comment_num: 7,
    tutorial:
      '### Kissing Video\n###### Make any two people kiss\n\n##### 1. Upload your image\n\nClear images from the waist up with the person in the center work better.\n\nThis works best with a portrait aspect ratio.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/AbcDeFgHiJkLmN.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![kiss](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/AbcDeFgHiJkLmN.mp4)\n',
    tagline: '',
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    creation_num: 108,
    template_id: '6707b71034110080fb021914',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'portrait',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali.webp',
    create_time: '2024-10-31T13:55:41.990Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Diwali',
    rank: 630,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-31T13:55:41.996Z',
    version: 1,
    tutorial:
      '\n### Diwali \n\n###### Create a cute Diwali themed animated character from a single image.\n\n##### 1. Input your Image.\n\nA clear image from the waist up where the face is clearly shown works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/T7mQpWnVx9zLbCJ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/T7mQpWnVx9zLbCJ.mp4)\n',
    creation_num: 68,
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    template_id: '67238c5eb7c22d70ef09aa97',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/apt_dance.webp',
    create_time: '2024-10-29T21:20:51.227Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/apt_dance.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/apt_dance.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'APT Dance',
    rank: 620,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-29T21:20:51.229Z',
    version: 1,
    cover_image_spec: {
      width: 3072,
      height: 5376,
    },
    creation_num: 24,
    template_id: '672151b3b7c22d70ef09aa93',
    commits: {
      is_like: true,
      likes_count: 3,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'portrait',
    cover_image: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T16-41-25-aeebc8ff.webp',
    create_time: '2024-12-06T18:09:32.357Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T16-41-25-aeebc8ff.webp',
        thumbnail_url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T16-41-25-aeebc8ff.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Outfit Swap',
    rank: 610,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-12-06T18:09:32.362Z',
    version: 1,
    tutorial:
      '\n### Outfit Swap\n\n##### 1. Choose clothing\n\nSelect garment type, which can be divided into Top、Bottom、Set or dress，\n\n<img src="https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T17-11-13-f3d85d20.png" style="max-height: none; width: 95%;">\n\nThen upload or select the clothing to be changed\n\n<img src="https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T17-11-13-6a181581.png" style="max-height: none; width: 95%;">\n\n##### 2. Choose the model\n\n<img src="https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T17-11-13-7d1a80da.png" style="max-height: none; width: 95%;">\n\n##### 3. Click the Create button to generate an image.\n\n![button](https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-11T17-11-13-981cf320.webp)',
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    creation_num: 81,
    template_id: '675300e1151cb72fc60d8c72',
    commits: {
      is_like: false,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'portrait',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/cartoonify.webp',
    create_time: '2024-10-22T18:09:32.357Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/cartoonify.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/cartoonify.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Cartoonify',
    rank: 610,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-22T18:09:32.362Z',
    version: 1,
    tutorial:
      '\n### Cartoonify \n\n###### Create a cute animated character from a single image.\n\n##### 1. Input your Image.\n\nA clear image from the waist up where the face is clearly shown works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/TkswJmDLbHxPzoQ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/TkswJmDLbHxPzoQ.mp4)\n',
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    creation_num: 78,
    template_id: '6717ea5c831109b4fd0ba654',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'The Great Gatsby',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-10-02T20:34:32.657Z',
    update_time: '2024-10-02T20:34:32.657Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Leonardo_DiCaprio_Champagne_Cheers.webp',
    category: 'meme',
    tags: ['hot', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Leonardo_DiCaprio_Champagne_Cheers.mp4',
        thumbnail_url:
          'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Leonardo_DiCaprio_Champagne_Cheers.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 600,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    comment_num: 0,
    tagline: 'Make your character toast like The Great Gatsby.',
    creation_num: 58,
    template_id: '66fdae58011d58a6100a04b1',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/trump_dance.webp',
    create_time: '2024-10-06T18:49:17.036Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/trump_dance.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/trump_dance.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Trump Dance',
    rank: 590,
    styles: [],
    tags: ['meme_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-06T18:49:17.036Z',
    version: 1,
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: '',
    creation_num: 69,
    template_id: '6702dbad6614785d5ffe6690',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/GGUM_Yeonjun.webp',
    create_time: '2024-10-14T18:06:24.463Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/GGUM_Yeonjun.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/GGUM_Yeonjun.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'GGUM Yeonjun',
    rank: 440,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-14T18:06:24.463Z',
    version: 1,
    cover_image_spec: {
      width: 576,
      height: 1024,
    },
    creation_num: 14,
    template_id: '670d5da0dfc51d2514893f6c',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'portrait',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/halloween.webp',
    create_time: '2024-10-26T11:08:27.532Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/halloween.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/halloween.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Halloween',
    rank: 420,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-26T11:08:27.536Z',
    version: 1,
    tutorial:
      '\n### Halloween \n\n###### Create a cute animated character from a single image.\n\n##### 1. Input your Image.\n\nA clear image from the waist up where the face is clearly shown works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/DHKcLxxwXTZh5ZY.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/DHKcLxxwXTZh5ZY.mp4)\n',
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    creation_num: 133,
    template_id: '671ccdab9c4388cd8608d5b3',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Magnetic_Illit.webp',
    create_time: '2024-10-14T18:06:24.279Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Magnetic_Illit.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Magnetic_Illit.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Magnetic Illit',
    rank: 420,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-14T18:06:24.279Z',
    version: 1,
    cover_image_spec: {
      width: 576,
      height: 1024,
    },
    creation_num: 3,
    template_id: '670d5da0dfc51d2514893f6b',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/NA_Hwasa.webp',
    create_time: '2024-10-14T18:06:24.096Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/NA_Hwasa.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/NA_Hwasa.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'NA Hwasa',
    rank: 400,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-14T18:06:24.096Z',
    version: 1,
    cover_image_spec: {
      width: 576,
      height: 1024,
    },
    creation_num: 1,
    template_id: '670d5da0dfc51d2514893f6a',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rockstar_Lisa.webp',
    create_time: '2024-10-14T18:06:23.913Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rockstar_Lisa.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rockstar_Lisa.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Rockstar Lisa',
    rank: 380,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-14T18:06:23.913Z',
    version: 1,
    cover_image_spec: {
      width: 576,
      height: 1024,
    },
    creation_num: 3,
    template_id: '670d5d9fdfc51d2514893f69',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'meme',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/skelify.webp',
    create_time: '2024-10-29T18:09:00.517Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/skelify.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/skelify.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Skelify',
    rank: 360,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-29T18:09:00.519Z',
    version: 1,
    tutorial:
      '\n### Skelify \n\n###### Replace a persons head with a spooky skull.\n\n##### 1. Input your Image.\n\nA clear image from the waist up where the face is clearly shown works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/K9vBQtYrWmP4xCJ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/K9vBQtYrWmP4xCJ.mp4)\n',
    creation_num: 23,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    template_id: '672124bc67cdfe2eb0062c12',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'meme',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/stick_them_up.webp',
    create_time: '2024-10-28T18:48:25.437Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/stick_them_up.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/Stick_em_Up2.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Stick em Up',
    rank: 360,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-28T18:48:25.439Z',
    version: 1,
    tutorial:
      '\n### Stick em Up \n\n###### Make a person pull out a gun.\n\n##### 1. Input your Image.\n\nClear images from the waist up with the person in the center work better. Hands being off screen will help with movement. \nThe character\'s body should face forward or towards the other character to avoid incorrect movements.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/ZfNpGJtqYZRm2PQ.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/ZfNpGJtqYZRm2PQ.mp4)\n',
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    creation_num: 61,
    template_id: '671fdc7986f7f424880fc522',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sticky_Kiss_of_life.webp',
    create_time: '2024-10-14T18:06:23.728Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sticky_Kiss_of_life.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sticky_Kiss_of_life.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Sticky Kiss of life',
    rank: 360,
    styles: [],
    tags: ['kpop_dance', 'for_fun_&_giggles'],
    update_time: '2024-10-14T18:06:23.728Z',
    version: 1,
    cover_image_spec: {
      width: 576,
      height: 1024,
    },
    creation_num: 2,
    template_id: '670d5d9fdfc51d2514893f68',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Viral Hugging Video',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T18:13:10.925Z',
    update_time: '2024-09-27T18:13:10.925Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Viral_Hugging_Video.webp',
    category: 'meme',
    tags: ['hot', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Viral_Hugging_Video.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Viral_Hugging_Video.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 300,
    comment_num: 1,
    tutorial:
      '### Hug Each Other\n###### Make any two people hug\n\n##### 1. Upload your image\n\nClear images from the waist up with the person in the center work better.\n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/pa8lMAK1hS2yFMj.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/TynFksxZfTAdrJ8.mp4)\n',
    tagline: 'Make two characters embrace.',
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    creation_num: 122,
    template_id: '66f6f5b672b59c604303b9c3',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Umbrella_Remix.webp',
    create_time: '2024-09-30T18:28:38.681Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Umbrella_Remix.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Umbrella_Remix.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Umbrella Remix - Rihanna',
    rank: 280,
    styles: [],
    tags: ['remix_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:38.681Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 66,
    template_id: '66faedd6245adf24583e7a04',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Animal Morph',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-28T13:02:15.930Z',
    update_time: '2024-09-28T13:02:15.931Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Animal_Morph.webp',
    category: 'meme',
    tags: ['hot', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Animal_Head.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Animal_Morph.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 260,
    tutorial:
      '### Animal Morph\n\n###### Transform a person\'s head into an animal\n\n##### 1. Upload your image\n\nClear images from the waist up with the person in the center work better.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/cdDoaCVX-OZ-vfx.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/h8R3dq-RZq3JKPl.mp4)\n',
    comment_num: 0,
    tagline: 'Turn any character\'s head into an animal.',
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    creation_num: 121,
    template_id: '66f7fe5772b59c604303b9c4',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Neymar_JR.webp',
    create_time: '2024-09-30T18:28:38.300Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Neymar_JR.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Neymar_JR.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Neymar JR - Parado no bailão',
    rank: 240,
    styles: [],
    tags: ['south_america_party_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:38.300Z',
    version: 1,
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 57,
    template_id: '66faedd6245adf24583e7a02',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Single_Ladies.webp',
    create_time: '2024-09-30T18:28:39.433Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Single_Ladies.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Single_Ladies.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Single Ladies',
    rank: 220,
    styles: [],
    tags: ['trending_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:39.433Z',
    version: 1,
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 1,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 84,
    template_id: '66faedd7245adf24583e7a08',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Flying Neon Shapes',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:13.673Z',
    update_time: '2024-09-26T19:06:13.673Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Flying_Neon_Shapes.webp',
    category: 'product',
    tags: ['tech_gadgets', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Abstract_Virtual_Background.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Flying_Neon_Shapes.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 200,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Slide in a funky, neon background for your product. ',
    creation_num: 50,
    template_id: '66f5b0a5a011ea77bc74aa71',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Wispy Smoke',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:10.756Z',
    update_time: '2024-09-26T19:06:10.756Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wispy_Smoke.webp',
    category: 'product',
    tags: ['beauty', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Aesthetic_Smoke.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wispy_Smoke.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 180,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 16,
    tagline: 'Add wisps of smoke to your product display.',
    creation_num: 12,
    template_id: '66f5b0a2a011ea77bc74aa62',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Blue Sky & Clouds',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:12.701Z',
    update_time: '2024-09-26T19:06:12.701Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Blue_Sky.webp',
    category: 'product',
    tags: ['furniture', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Clouds.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Blue_Sky.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 160,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Send your product soaring through the clouds.',
    creation_num: 7,
    template_id: '66f5b0a4a011ea77bc74aa6c',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'basic',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali_greeting_card.webp',
    create_time: '2024-10-31T19:47:54.580Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali_greeting_card.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/diwali_greeting_card.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Diwali Greeting Card',
    rank: 100,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-31T19:47:54.582Z',
    version: 1,
    tutorial:
      '\n### Diwali Greeting Card \n\n###### Apply your text to a video greeting card.\n\n##### 1. Input your Text.\n\nFor example, "Everyone".\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/Z4kQpVmX8nTrYjB.mp4)\n',
    cover_image_spec: {
      width: 720,
      height: 960,
    },
    creation_num: 14,
    template_id: '6723deeab61be6a8eb005103',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'meme',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/zombify.webp',
    create_time: '2024-10-30T17:27:38.979Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/zombify.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/cover/zombify.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Zombify',
    rank: 100,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-10-30T17:27:38.981Z',
    version: 1,
    creation_num: 11,
    tutorial:
      '\n### Zombify \n\n###### Turn a person into a zombie.\n\n##### 1. Input your Image.\n\nA clear portrait of the head and shoulders works best. Any clothing included in the input image might also be worn by the generated character.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/X2nLpQvTgZ9wYmD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/X2nLpQvTgZ9wYmD.mp4)\n',
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    template_id: '67226c8bb7c22d70ef09aa95',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/What_it_is.webp',
    create_time: '2024-09-30T18:28:39.245Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/What_it_is.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/What_it_is.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'What it is - Doechii',
    rank: 100,
    styles: [],
    tags: ['trending_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:39.245Z',
    version: 1,
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 73,
    template_id: '66faedd7245adf24583e7a07',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wannabe.webp',
    create_time: '2024-09-30T18:28:39.058Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wannabe.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wannabe.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Wannabe - GloRilla',
    rank: 100,
    styles: [],
    tags: ['trending_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:39.058Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 59,
    template_id: '66faedd7245adf24583e7a06',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Apple.webp',
    create_time: '2024-09-30T18:28:38.868Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Apple.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Apple.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Apple - Charlie XCX',
    rank: 100,
    styles: [],
    tags: ['trending_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:38.868Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 61,
    template_id: '66faedd6245adf24583e7a05',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brazilian_Groove.webp',
    create_time: '2024-09-30T18:28:38.493Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brazilian_Groove.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brazilian_Groove.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Brazilian Groove',
    rank: 100,
    styles: [],
    tags: ['south_america_party_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:38.493Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 52,
    template_id: '66faedd6245adf24583e7a03',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Dark_Room_Party.webp',
    create_time: '2024-09-30T18:28:38.097Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Dark_Room_Party.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Dark_Room_Party.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Dark Room Party',
    rank: 100,
    styles: [],
    tags: ['south_america_party_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:38.097Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 1,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 57,
    template_id: '66faedd6245adf24583e7a01',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Just_Dance.webp',
    create_time: '2024-09-30T18:28:37.901Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Just_Dance.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Just_Dance.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Just Dance',
    rank: 100,
    styles: [],
    tags: ['for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:37.901Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 53,
    template_id: '66faedd5245adf24583e7a00',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brother_Louie.webp',
    create_time: '2024-09-30T18:28:37.711Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brother_Louie.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Brother_Louie.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Brother Louie Mix \'98',
    rank: 100,
    styles: [],
    tags: ['remix_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:37.711Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 50,
    template_id: '66faedd5245adf24583e79ff',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    author_id: '66d71b856de2e2c44f287250',
    category: 'dance',
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Alibi_Dance.webp',
    create_time: '2024-09-30T18:28:37.522Z',
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Alibi_Dance.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Alibi_Dance.webp',
      },
    ],
    introduction: '',
    is_enabled: true,
    is_public: true,
    name: 'Alibi Dance',
    rank: 100,
    styles: [],
    tags: ['trending_dance', 'south_america_party_dance', 'for_fun_&_giggles'],
    update_time: '2024-09-30T18:28:37.522Z',
    version: 1,
    cover_image_spec: {
      width: 768,
      height: 1280,
    },
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 54,
    template_id: '66faedd5245adf24583e79fe',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Wop X Untouchable',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:43:50.763Z',
    update_time: '2024-09-27T16:43:50.763Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wop_Untouchable.webp',
    category: 'dance',
    tags: ['remix_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wop_Untouchable.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Wop_Untouchable.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 78,
    template_id: '66f6e0c697f89de44d5f646f',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Griddy',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:40.079Z',
    update_time: '2024-09-27T16:42:40.079Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Griddy.webp',
    category: 'dance',
    tags: ['meme_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Griddy.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Griddy.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 109,
    template_id: '66f6e080ee0cbc2da27c1d8a',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'One Dance - Drake',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:39.889Z',
    update_time: '2024-09-27T16:42:39.889Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/One_Dance.webp',
    category: 'dance',
    tags: ['south_america_party_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/One_Dance.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/One_Dance.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 108,
    template_id: '66f6e07fee0cbc2da27c1d89',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Keep Up Dance',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:39.654Z',
    update_time: '2024-09-27T16:42:39.654Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Keep_Up.webp',
    category: 'dance',
    tags: ['trending_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Keep_Up.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Keep_Up.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 72,
    template_id: '66f6e07fee0cbc2da27c1d88',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'The Wolf Dance',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:39.462Z',
    update_time: '2024-09-27T16:42:39.462Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/The_Wolf.webp',
    category: 'dance',
    tags: ['meme_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/The_Wolf.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/The_Wolf.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    comment_num: 1,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 70,
    template_id: '66f6e07fee0cbc2da27c1d87',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Beat that boy with a bat',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:39.269Z',
    update_time: '2024-09-27T16:42:39.269Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beat_that_boy.webp',
    category: 'dance',
    tags: ['meme_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beat_that_boy.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beat_that_boy.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 57,
    template_id: '66f6e07fee0cbc2da27c1d86',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Jus\' Know',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-27T16:42:39.052Z',
    update_time: '2024-09-27T16:42:39.052Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Jus_Know.webp',
    category: 'dance',
    tags: ['trending_dance', 'for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Jus_Know.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Jus_Know.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    tutorial:
      '\n[//]: # (id: template:dance)\n\n### Dance Templates\n\n###### Turn your image into a dance video\n\n##### 1. Input your Image.\n\nA clear full body image will work a lot better. \n\nThis works best at 9:16 aspect ratio or 720x1280 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/L80er8UusYjZjdD.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/THbIQAqgFHBSrbg.mp4)\n',
    cover_image_spec: {
      width: 1152,
      height: 2048,
    },
    comment_num: 0,
    tagline: 'Boogie to the latest trendy or iconic dance.',
    creation_num: 65,
    template_id: '66f6e07fee0cbc2da27c1d85',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Sleek Gray Studio',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:14.236Z',
    update_time: '2024-09-26T19:06:14.236Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sleek_Gray_Studio.webp',
    category: 'product',
    tags: ['tech_gadgets', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Minimalist_Studio.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sleek_Gray_Studio.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Showcase your product in a sleek, gray studio.',
    creation_num: 36,
    template_id: '66f5b0a6a011ea77bc74aa74',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Modern Office',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:14.049Z',
    update_time: '2024-09-26T19:06:14.049Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Office.webp',
    category: 'product',
    tags: ['tech_gadgets', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Office.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Office.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Insert your product in a warmly lit, modern office.',
    creation_num: 15,
    template_id: '66f5b0a6a011ea77bc74aa73',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Rotating Neon Platform',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:13.860Z',
    update_time: '2024-09-26T19:06:13.860Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rotating_Neon_Platform.webp',
    category: 'product',
    tags: ['tech_gadgets', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Neon_Platform.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rotating_Neon_Platform.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Display your product on a funky neon rotating platform',
    creation_num: 35,
    template_id: '66f5b0a5a011ea77bc74aa72',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Two-toned Room',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:13.486Z',
    update_time: '2024-09-26T19:06:13.486Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Two_toned_Room.webp',
    category: 'product',
    tags: ['tech_gadgets', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Pastel_Gradient.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Two_toned_Room.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Insert your product into a white room with a turquoise wall. ',
    creation_num: 14,
    template_id: '66f5b0a5a011ea77bc74aa70',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Beach & Ocean Waves',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:13.292Z',
    update_time: '2024-09-26T19:06:13.292Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beach_Ocean_Waves.webp',
    category: 'product',
    tags: ['furniture', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sunset_Beach.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beach_Ocean_Waves.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Transport your product to the shores of a beach during sunset. ',
    creation_num: 12,
    template_id: '66f5b0a5a011ea77bc74aa6f',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Elegant Dining Table',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:13.103Z',
    update_time: '2024-09-26T19:06:13.103Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Elegant_Dining_Table.webp',
    category: 'product',
    tags: ['furniture', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Luxury_Hotel.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Elegant_Dining_Table.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Add your product on top an elegant chestnut dining table.',
    creation_num: 23,
    template_id: '66f5b0a5a011ea77bc74aa6e',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Modern Living Room',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:12.892Z',
    update_time: '2024-09-26T19:06:12.892Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Living_Room.webp',
    category: 'product',
    tags: ['furniture', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Living_Room.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Living_Room.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Insert your product into a neutral-toned living room. ',
    creation_num: 27,
    template_id: '66f5b0a4a011ea77bc74aa6d',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Beige Room',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:12.513Z',
    update_time: '2024-09-26T19:06:12.513Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beige_Room.webp',
    category: 'product',
    tags: ['furniture', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Neutral_Room.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Beige_Room.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Your product stands out in a neutral beige room. ',
    creation_num: 17,
    template_id: '66f5b0a4a011ea77bc74aa6b',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Patio Garden',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:12.322Z',
    update_time: '2024-09-26T19:06:12.322Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Patio_Garden.webp',
    category: 'product',
    tags: ['food_and_beverage', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Patio_Garden.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Patio_Garden.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Take your product outside to a warm patio garden.',
    creation_num: 12,
    template_id: '66f5b0a4a011ea77bc74aa6a',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Cosy Dining Table',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:12.132Z',
    update_time: '2024-09-26T19:06:12.132Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Cosy_Dining_Table.webp',
    category: 'product',
    tags: ['food_and_beverage', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Fine_Dining.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Cosy_Dining_Table.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Highlight your product on a dining table with background candles.',
    creation_num: 11,
    template_id: '66f5b0a4a011ea77bc74aa69',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Flaming Grill',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:11.897Z',
    update_time: '2024-09-26T19:06:11.897Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Flaming_Grill.webp',
    category: 'product',
    tags: ['food_and_beverage', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Flaming_Grill.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Flaming_Grill.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Make your product sizzle in front of flickering flames.',
    creation_num: 33,
    template_id: '66f5b0a3a011ea77bc74aa68',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Simple Dining Table',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:11.701Z',
    update_time: '2024-09-26T19:06:11.701Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Simple_Dining_Table.webp',
    category: 'product',
    tags: ['food_and_beverage', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Cosy_Kitchen.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Simple_Dining_Table.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Set your product on top a simple wooden table.',
    creation_num: 9,
    template_id: '66f5b0a3a011ea77bc74aa67',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Tranquil Waterfall',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:11.512Z',
    update_time: '2024-09-26T19:06:11.512Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Tranquil_Waterfall.webp',
    category: 'product',
    tags: ['food_and_beverage', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Tranquil_Waterfall.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Tranquil_Waterfall.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Place your product in front of a calming waterfall. ',
    creation_num: 11,
    template_id: '66f5b0a3a011ea77bc74aa66',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Rippling Water',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:11.318Z',
    update_time: '2024-09-26T19:06:11.318Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rippling_Water.webp',
    category: 'product',
    tags: ['beauty', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Shallow_Water.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Rippling_Water.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Your product floats on top of gently rippling water.',
    creation_num: 9,
    template_id: '66f5b0a3a011ea77bc74aa65',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Modern Bathroom',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:11.133Z',
    update_time: '2024-09-26T19:06:11.133Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Bathroom.webp',
    category: 'product',
    tags: ['beauty', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Bathroom.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Modern_Bathroom.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    comment_num: 1,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    tagline: 'Your product shines in front of a bathroom vanity and sink. ',
    creation_num: 9,
    template_id: '66f5b0a3a011ea77bc74aa64',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Luxurious Fabric',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:10.944Z',
    update_time: '2024-09-26T19:06:10.944Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Luxurious_Fabric.webp',
    category: 'product',
    tags: ['beauty', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Luxurious_Fabric.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Luxurious_Fabric.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    comment_num: 0,
    tagline: 'Surround your product in flowing, silky fabric. ',
    creation_num: 9,
    template_id: '66f5b0a2a011ea77bc74aa63',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Bright Yellow Backdrop',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-26T19:06:10.568Z',
    update_time: '2024-09-26T19:06:10.568Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Bright_Yellow_Backdrop.webp',
    category: 'product',
    tags: ['beauty', 'for_commerical_use'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Yellow_Photography_Studio.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Bright_Yellow_Backdrop.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    comment_num: 4,
    cover_image_spec: {
      width: 1280,
      height: 721,
    },
    tutorial:
      '\n[//]: # (id: template:product)\n\n### Product Template\n\n###### Create a video of your product in a new background.\n\n##### 1. Input your Image.\n\nThis works best at 16:9 aspect ratio or 1280x720 resolution.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/l44kj27WPMZEAvK.webp)\n\n##### 2. Select your Product\n\nSelect the product in your image. Use the + and - options to get a more accurate selection.\n\n\n![sam](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5il1E8CIwX2pKHv.webp)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/mq6P2D05aUyOafN.mp4)\n',
    tagline: 'Brighten your product with a sunny hellow background. ',
    creation_num: 13,
    template_id: '66f5b0a2a011ea77bc74aa61',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Sketch Drawing',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-24T20:20:37.770Z',
    update_time: '2024-09-24T20:20:37.770Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sketch_Drawing.webp',
    category: 'styles',
    tags: ['for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sketch_Animation.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Sketch_Drawing.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '### Realism photo to sketch video\n\n###### Turn your images to an animated sketch\n\n##### 1. Input your Image.\n\nA clear image with good contrast works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/NY3L5hR9Ci8k112.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/Vr1r2GhqjEKLv3V.mp4)\n',
    comment_num: 0,
    tagline: 'Transform your image into a sketch drawing. ',
    creation_num: 30,
    template_id: '66f31f15617c2255e00b3132',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Photo Restoration',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-23T12:48:55.914Z',
    update_time: '2024-09-23T12:48:55.916Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Photo_Restoration.webp',
    category: 'styles',
    tags: ['for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Photo_Restoration.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Photo_Restoration.webp',
      },
    ],
    styles: [],
    is_public: false,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '### Old photo to realism video\n\n###### Turn old photos into realistic colour videos\n\n##### 1. Input your Image.\n\nA clear image with good contrast works best.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/-R2hZt5LaK4ViEE.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/UVh4TMLuZ2U_h8U.mp4)\n',
    comment_num: 0,
    tagline: 'Enhance old photos with a modern polish.',
    creation_num: 26,
    template_id: '66f154002cfa251bbd0f2ffd',
    commits: {
      is_like: false,
      likes_count: 0,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Cartoon Style',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-23T12:42:07.514Z',
    update_time: '2024-09-23T12:42:07.515Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Cartoon_Style.webp',
    category: 'styles',
    tags: ['for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Anime_Morph.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Cartoon_Style.webp',
      },
    ],
    styles: [],
    is_public: false,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '### Anime Morph\n\n###### Morph your photos into Anime\n\n##### 1. Input your Image.\n\nClear images from the waist up with the person in the center work better.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/gruxLbDJhcNfpjN.webp)\n\n##### 2. Generate your video\n\nClick the Create button to generate your video.\n\n![dance](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/HLQzln_dOWvtWUQ.mp4)\n',
    comment_num: 0,
    tagline: 'Transform characters into anime cartoons. ',
    creation_num: 36,
    template_id: '66f154002cfa251bbd0f2ffc',
    commits: {
      is_like: true,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Face Swap',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-03T16:57:52.914Z',
    update_time: '2024-09-03T16:57:52.915Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Face_Swap.webp',
    category: 'basic',
    tags: ['for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Face_Swap.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Face_Swap.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '### Faceswap\n\n###### Apply your image to a motion video.\n\n##### 1. Input your Image.\n\nA clear image from the waist up will work a lot better. \n\nThis should match the same size as the video you want to apply it to.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/giHlRX9TV4iB6SB.webp)\n\n##### 2. Upload your motion video.\n\nUse a clear, well-lit video to ensure better results.\n\n![motion](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/FCJ0xQd0fsKL1pp.mp4)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![output](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/XzpL4EstFNWX-ey.mp4)\n',
    comment_num: 0,
    tagline: '',
    creation_num: 174,
    template_id: '66dad59827c229ace40d37ae',
    commits: {
      is_like: false,
      likes_count: 1,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Swap Body Movements',
    author_id: '66d71b856de2e2c44f287250',
    introduction: '',
    create_time: '2024-09-03T16:57:52.914Z',
    update_time: '2024-09-03T16:57:52.914Z',
    version: 1,
    cover_image: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Swap_Body_Movements.webp',
    category: 'basic',
    tags: ['for_fun_&_giggles'],
    examples: [
      {
        media_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Animate_Yourself.mp4',
        thumbnail_url: 'https://cdnvb5.haiper.ai/assets/webapp/templates/examples/Swap_Body_Movements.webp',
      },
    ],
    styles: [],
    is_public: true,
    is_enabled: true,
    rank: 100,
    cover_image_spec: {
      width: 1280,
      height: 720,
    },
    tutorial:
      '### Animate yourself\n\n###### Apply your image to a motion video.\n\n##### 1. Input your Image.\n\nA clear image from the waist up will work a lot better. \n\nThis should match the same size as the video you want to apply it to.\n\n![original](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/ktem_Rb6AeiIos3.webp)\n\n##### 2. Upload your motion video.\n\nUse a clear, well-lit video to ensure better results.\n\n![motion](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/vgagKhqxfitRdYR.mp4)\n\n##### 3. Generate your video\n\nClick the Create button to generate your video.\n\n![output](https://cdnvb5.haiper.ai/assets/webapp/templates/tutorials/5oBXH4TCrCYHBoH.mp4)\n',
    comment_num: 0,
    tagline: 'Make a character mimic your body movements. ',
    creation_num: 518,
    template_id: '66dad59f27c229ace40d37af',
    commits: {
      is_like: true,
      likes_count: 2,
    },
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
]
