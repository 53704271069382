'use client'

import IconCodeOutline from '@haiper/icons-svg/icons/outline/code.svg'
import IconFire2 from '@/public/assets/fire2.svg'
import CreationInput from '@/components/creation-input'
import Link from '@/components/link'
import { CreationMode } from '@/types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/navigation'
import useAmplitude from '@/hooks/useAmplitude'
import { useAtom, useSetAtom } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom, subscriptionDialogContentAtom } from '@/atoms'
import { cls, stopPropagation, whisper } from '@/utils'
import useAutoCleanCreationCache from '@/hooks/useAutoCleanCreationCache'
import { useCachedSwitches } from '@/hooks/useSwitches'
import CreditUpgradeButton from '@/components/credit-upgrade-button'
import { NO_OUTLINE_STYLE, creationInputTranslateStyle } from '@/constants'
import LogoSymbol from '@/public/assets/logo-symbol.svg'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'
import SocialLinks from '@/components/social-links'
import GlobalContainer from '@/components/global-container'
import useAutoCleanOutputCache from '@/hooks/useAutoCleanOutputCache'
// import TemplatesExploreCompact from '@/components/templates/explore/compact'
import HomeContainer from './container'
import HomeEvents from './events'
import Scrollable from '@/components/scrollable'
import EventCard from '@/components/event-card'
import useBrowser from '@/hooks/useBrowser'
import Button from '@/components/button'
import useCheckInStreak from '@/hooks/useCheckInStreak'
import usePopups from '@/hooks/usePopups'
// import HomeCreations from './creations'
import SpotlightWorks from '../spotlight/works'
import SubmitWorkButton from '@/components/submit-work-button'
import { useCachedVisibleMarketingEvents } from '@/hooks/useVisibleMarketingEvents'
import { useBreakpoint } from '@/hooks/useBreakPoint'

const showModel2Event = false

export default function Home() {
  const router = useRouter()
  const { data: creationModeOptions } = useCreationModes()

  const { isBelowMd } = useBreakpoint('md')
  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const { data: marketingEvents } = useCachedVisibleMarketingEvents()
  const hasVisibleMarketingEvents = marketingEvents?.length > 0
  const hasOnlyOneMarketingEvent = marketingEvents?.length === 1
  const showEventsWithoutFrame = (hasOnlyOneMarketingEvent || true) && !isBelowMd

  const { track } = useAmplitude()

  // clear all cache data
  useAutoCleanCreationCache()
  useAutoCleanOutputCache()

  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)

  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)

  useEffect(() => {
    track('view:home')
  }, [track])

  useEffect(() => {
    whisper('home.didMount')
  }, [])

  const browser = useBrowser()

  const model2Event: haiper.MarketingEvent = useMemo(() => {
    return {
      event_id: 'model-2',
      title: <span className='text-heading-sm font-bold tracking-15'>Introduce our new model</span>,
      // abstract: 'Haiper 2.0',
      abstract: <span className='text-heading-4xl leading-[100%]'>Haiper 2.1</span>,
      content: '',
      cover_image_url: 'https://assets.haiper.ai/webapp/images/features/pony.webp',
      visible: true,
      start_time: '',
      end_time: '',
      create_time: '',
      update_time: '',
      action: {
        name: 'Details',
        type: 'custom',
        payload: {
          // onClick: openModel2NewFeatureDialog,
        },
      },
      is_deleted: false,
    }
  }, [])

  const appsScrollViewPortRef = useRef<HTMLDivElement>(null)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)

  const renderCreationModeItem = useCallback(
    (creationMode: CreationMode, index: number) => {
      const { Icon, iconClassName } = creationMode
      const needUpgrade = creationMode.membersOnly && isFreePlan

      return (
        <div
          key={creationMode.mode}
          className={cls(
            'relative cursor-pointer flex items-center bg-surface rounded-[16px] p-3 border-2 border-b-4 border-solid border-border hover:border-border-hover hover:bg-surface-hover text-text flex-1 h-[112px] w-[152px] min-w-[152px] shrink-0',
            mode === creationMode.mode ? 'cursor-default' : '',
            !creationMode.available ? 'hover:bg-white/5 shadow-none cursor-not-allowed hover:border-border' : '',
          )}
          aria-label='creation-mode-item'
          data-testid={`creation-mode-item-${creationMode.mode}`}
          onClick={(e) => {
            if (!creationMode.available) return
            if (needUpgrade) {
              setSubscriptionDialogContent({
                message: `Upgrade to Haiper Membership to access the ${creationMode.name} feature.`,
              })
              return
            }

            setCreationInput((prev) => ({
              ...prev,
              creation: undefined,
              mode: creationMode.mode,
              expanded: true,
              focusing: true,
            }))
            setActiveTemplateId(null)
            // scroll current element's left to appsScrollViewPortRef's left if it is out of appsScrollViewPortRef's left or right
            const viewport = appsScrollViewPortRef.current
            if (viewport) {
              const left = e.currentTarget.offsetLeft
              const right = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth
              const offset = 50
              if (left < viewport.scrollLeft) {
                viewport.scrollBy({
                  left: left - viewport.scrollLeft - offset,
                  behavior: 'smooth',
                })
              } else if (right > viewport.scrollLeft + viewport.clientWidth) {
                viewport.scrollBy({
                  left: right - viewport.scrollLeft - viewport.clientWidth + offset,
                  behavior: 'smooth',
                })
              }
            }
          }}
        >
          <div className={cls('flex gap-2.5 flex-col pt-2.5 w-full')}>
            <div className='flex flex-col'>
              <div className='px-1 flex-1 flex items-center justify-between'>
                <div
                  className={cls(
                    'text-text text-body-md font-bold tracking-15 leading-4',
                    !creationMode.available ? 'text-text-subdued' : '',
                    needUpgrade ? 'text-text-disabled' : '',
                  )}
                >
                  {creationMode.name}
                </div>
                {creationMode.available ? null : (
                  <div className={cls('text-body-sm text-text-subdued ml-auto tracking-15')}>Coming soon</div>
                )}
              </div>
              {creationMode.membersOnly ? (
                <div className='flex items-center gap-1 text-body-sm px-1'>
                  <Link
                    href='/membership'
                    className='leading-5 tracking-15 font-bold text-text-interactive'
                    onClick={stopPropagation as any}
                  >
                    Members
                  </Link>
                  <span className=''>only</span>
                </div>
              ) : null}
              {creationMode.newFeature ? (
                <div className='absolute inset-y-0 left-4 flex items-center'>
                  <div className='flex items-center gap-1 text-body-xs md:text-body-sm leading-4 text-text-interactive font-medium whitespace-nowrap'>
                    <span className=''>{creationMode.newFeature}</span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={cls('size-10 rounded-full shrink-0 p-2 text-icon-on-color self-end', iconClassName)}>
              <Icon className={cls(!creationMode.available || needUpgrade ? 'opacity-50' : '')} />
            </div>
          </div>
          {creationMode.isNew ? <BadgeNew className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
          {creationMode.isUpdated ? <BadgeUpdate className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
        </div>
      )
    },
    [isFreePlan, mode, setActiveTemplateId, setCreationInput, setSubscriptionDialogContent],
  )

  const { lastCheckInStreak } = useCheckInStreak()
  const { openCheckinDialog } = usePopups()

  return (
    <div className='size-full overflow-y-auto no-scrollbar' data-testid='explore-page'>
      <GlobalContainer className='static'>
        <div className='flex justify-between items-center gap-4 w-full'>
          <div className='flex items-center gap-4 md:gap-6'>
            <div className='text-heading-4xl text-text font-bold hidden md:flex' aria-label='Home'>
              Home
            </div>
            <div className='flex md:hidden justify-start text-text' aria-label='logo'>
              <Link href='/' className={cls('relative size-10 p-1', NO_OUTLINE_STYLE)} aria-label='signin logo'>
                {/* <Logo width={176} height={72} /> */}
                <LogoSymbol className='size-8' />
              </Link>
            </div>
          </div>
          <div className='flex gap-4 items-center'>
            <SocialLinks />
            <Button variant='primary' className='hidden md:flex py-2 px-3 h-10'>
              <Link href='/haiper-api' className='flex items-center gap-1'>
                <IconCodeOutline className='size-6 shrink-0 text-icon-on-color' />
                <span className='px-1'>API</span>
              </Link>
            </Button>
            <Button variant='outline' className='flex p-2 h-10' onClick={openCheckinDialog}>
              <div className='flex items-center'>
                <IconFire2 className='size-5 shrink-0 text-orange-600' />
                <span className='px-1'>{lastCheckInStreak}</span>
              </div>
            </Button>
            <CreditUpgradeButton showMembership className='md:h-10 px-2' source='explore' />
          </div>
        </div>
      </GlobalContainer>
      <div className='flex flex-col p-4 md:px-14 md:py-20 md:pt-2 gap-8'>
        <div className='flex flex-col md:flex-row items-center gap-8 md:h-50' aria-label='first-row'>
          <HomeContainer
            title='Apps'
            className={cls(
              'mb-4 md:mb-8 w-full md:w-0 md:flex-1 shrink relative',
              switchesLoading && !switches ? 'hidden' : '',
              'mb-0 md:mb-0',
            )}
            aria-label='creation-modes'
            data-testid='creation-modes'
          >
            <Scrollable className='w-full flex gap-3'>
              {creationModeOptions.filter((e) => !e.hidden).map(renderCreationModeItem)}
            </Scrollable>
          </HomeContainer>
          {showModel2Event && (
            <EventCard
              hideStatus
              source='home-top'
              className='relative rounded-xl overflow-hidden cursor-pointer w-full md:w-1/4 min-w-60 h-full min-h-40 md:min-h-50 xl:min-h-full z-0 border border-border'
              data={model2Event}
              variant='primary'
            />
          )}
          {hasVisibleMarketingEvents ? (
            showEventsWithoutFrame ? (
              <HomeEvents frameless className='w-100' />
            ) : (
              <HomeContainer
                title='Events'
                className='md:w-1/3 w-full h-full self-end md:h-full md:col-span-1'
                moreLink='/events'
              >
                {/* <HomeEvents className='' frameless={hasOnlyOneMarketingEvent} /> */}
                <HomeEvents className='' />
              </HomeContainer>
            )
          ) : null}
        </div>
        {/* <div className='flex flex-col justify-between gap-8 overflow-hidden' aria-label='second-row'>
          <HomeContainer
            title='Templates'
            className={cls('flex-1 grow md:h-full md:col-span-2', browser?.name === 'safari' && 'max-h-max')}
            moreLink='/templates'
            clickMoreEventName='click:home:more-templates'
          >
            <TemplatesExploreCompact className='' />
          </HomeContainer>
          <HomeContainer title='Events' className='w-full h-full self-end md:h-full md:col-span-1' moreLink='/events'>
            <HomeEvents className='' />
          </HomeContainer>
        </div> */}
        <div className='w-full flex flex-col gap-4'>
          <div className='w-full flex items-center justify-between'>
            <span className='text-heading-2xl font-bold'>Spotlight</span>
            <SubmitWorkButton />
          </div>
          <SpotlightWorks />
        </div>
        <footer
          className={cls(
            'z-[2] w-full transition-all fixed md:absolute bottom-16 md:bottom-0 left-1/2 translate-x-[-50%] rounded-t-[30px] md:rounded-t-[24px] pointer-events-none',
          )}
          style={creationInputTranslateStyle}
        >
          <div className='size-full py-0 md:py-5 rounded-t-[30px] md:rounded-t-[24px]'>
            <CreationInput
              className='w-full items-end'
              toolbarDirection='up'
              onGenerate={(creationId: string) => {
                track('click:creation:generate', { creation_id: creationId })
                router.push('/creations')
              }}
            />
          </div>
        </footer>
      </div>
    </div>
  )
}
