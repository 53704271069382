import { getStripePricingTable } from '@/service/pay.service'
import { useCachedService } from '@banyudu/use-service'
import { useMemo } from 'react'
import useIsLogin from './useIsLogin'

const skipableGetStripePricingTable = async (params?: { skip?: boolean }) => {
  if (params?.skip) return null
  const res = await getStripePricingTable()
  return res
}

const _useCachedPricingTable = useCachedService(skipableGetStripePricingTable)

export const useCachedPricingTable = () => {
  const isLogin = useIsLogin()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  return _useCachedPricingTable(params)
}

// export default usePricingTable
